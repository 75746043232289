const data = [
    {
        id:1,
        title:"Supernova",
        image:"../assets/images/project-1.jpg",
        client: "Supertech Limited",
        location:"Sector-94, Noida",
        valuw:"INR 21 Crores",
        status:"Ongoing",
    },
    {
        id:2,
        title:"North Eye",
        image:"../assets/images/project-2.jpg",
        client: "Supertech Limited",
        location:"Sector-74, Noida",
        valuw:"INR 8 Crores",
        status:"Ongoing",
    },
    {
        id:3,
        title:"Cape Town",
        image:"../assets/images/project-3.jpg",
        client: "Supertech Limited",
        location:"Sector-74, Noida",
        valuw:"INR 24 Crores",
        status:"Ongoing (80% completed)",
    },
    {
        id:4,
        title:"Wave City Center",
        image:"../assets/images/project-4.jpg",
        client: "Wave Infra13 Private Limited",
        location:"Sector-32, Noida",
        valuw:"INR 4.75 Crores",
        status:"Ongoing",
    },
    {
        id:5,
        title:"Eco Village - 1",
        image:"../assets/images/project-5.jpg",
        client: "Supertech Limited",
        location:"Greater Noida (West)",
        valuw:"INR 7 Crores",
        status:"Completed",
    },
    {
        id:6,
        title:"34 Pavillion",
        image:"../assets/images/project-6.jpg",
        client: "Supertech Limited",
        location:"Sector-34, Noida",
        valuw:"INR 4.5 Crores",
        status:"Completed",
    },
    {
        id:7,
        title:"Emerald Court",
        image:"../assets/images/project-7.jpg",
        client: "Supertech Limited",
        location:"Sector-93, Noida",
        valuw:"INR 8.25 Crores",
        status:"Ongoing",
    },
    {
        id:8,
        title:"Romano",
        image:"../assets/images/project-8.jpg",
        client: "Supertech Limited",
        location:"Greater Noida (West)",
        valuw:"INR 4 Crores",
        status:"Ongoing",
    },
    {
        id:9,
        title:"Livington",
        image:"../assets/images/project-9.jpg",
        client: "Supertech Limited",
        location:"Ghaziabad",
        valuw:"INR 3.5 Crores",
        status:"Completed",
    },
    {
        id:10,
        title:"Eco Village - II",
        image:"../assets/images/project-10.jpg",
        client: "Supertech Limited",
        location:"Greater Noida (West)",
        valuw:"INR 2.5 Crores",
        status:"Completed",
    },
    {
        id:11,
        title:"Yashoda Hospital",
        image:"../assets/images/project-11.jpg",
        client: "Yashoda Hospitals Pvt. Ltd.",
        location:"Kaushambi, Ghaziabad",
        valuw:"INR 1.25 Crores",
        status:"Completed",
    },
    {
        id:12,
        title:"UP Country",
        image:"../assets/images/project-12.jpg",
        client: "Supertech Limited",
        location:"Greater Noida",
        valuw:"INR 1.5 Crores",
        status:"Completed",
    },
    {
        id:13,
        title:"CZAR Suites",
        image:"../assets/images/project-13.jpg",
        client: "Supertech Limited",
        location:"Greater Noida",
        valuw:"INR 5 Crores",
        status:"Completed",
    },
   
];

export default data;