import React, { Component } from 'react'
import Breadcrumb from '../Common/Breadcumb'
import {Helmet} from "react-helmet";

export default class Testimonials extends Component {
  render() {
    return <>
    <Helmet>
      <title>Snappy Services - Client Testimonials</title>

<meta name="description" content="Snappy Services is the best service provider for Electrical Service, Electrical Motor Service, Solar Services and Home Appliances and Home Automation Services.  We offer various services such as installation, repairs maintenance and other support services to delight our customers."/>
<meta name="keywords" content="Snappy Services, Snappy Serv, Snappyserv, snappyserv.com, Electrical Service Provider, Home Automation, Electrical Repair and services, Home Appliances Repair and services, Home Appliances company in Ghaziabad, Solar Repair and service, Solar service provider, Solar appliances services in Ghaziabad"/>
<link rel="canonical" href="https://www.snappyserv.com/aboutus.aspx" />
    </Helmet>
      <Breadcrumb pageTitle="Testimonials" pageName="Testimonials"/>
      <div id="about">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
              <div className="cmsmasters_quotes cmsmasters_quotes_grid quote_two">
                <div className="cmsmasters_quotes_list">
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte2.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Kush srivastava</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>Wonderful Service by Snappy technician. Costed less than what local AC technician were asking and work is done in very professional manner.</p>
                      </div>
                    </article>
                  </div>
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte4.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Rinki Arora</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>We got our Geyser repaired from Snappy services.Had a nice experience. Technicians were good in providing services.. They tried their best to resolve the issue.</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cmsmasters_quotes_list">
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte3.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Vivek Goel</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>I am happy to see the professional work done by technicians without much cluttering in house.</p>
                      </div>
                    </article>
                  </div>
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte2.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Sudhanshu Chauhan
                          </h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>First time avail the services. Fast service. Reasonable price compare to urban clap. Professional person. No extra charges demanded.</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cmsmasters_quotes_list">
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte2.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Pratham Rathi</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>The electricians were good and professional the service was on time and they even help till the end no matter how many times you call they provided a satisfactory service and helped me out a lot thanks a lot for your help Snappy Services.</p>
                      </div>
                    </article>
                  </div>
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte4.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Kuldeep Arora</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>Great experience they give best service I am very happy to repair my many electrical equipment beside change like my wife pressing machine and dryer was not working all shopkeeper said it can't be repair but snappy repair my all equipment thanks team of snappy.</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="cmsmasters_quotes_list">
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte3.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">MONA THAKKRAL</h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>Got AC service done. Work is done to the satisfaction. Good job . Thanks</p>
                      </div>
                    </article>
                  </div>
                  <div className="cmsmasters_quote">
                    <article className="cmsmasters_quote_inner">
                      <div className="cmsmasters_quote_img_info_wrap">
                        <div className="cmsmasters_quote_image"><img src="../assets/images/02home/clinte2.png" alt="Kevin" /></div>
                        <div className="cmsmasters_quote_info_wrap">
                          <h3 className="cmsmasters_quote_title">Ashish Vaish
                          </h3>
                          <h5 className="cmsmasters_quote_subtitle">Customer</h5>
                        </div>
                      </div>
                      <div className="cmsmasters_quote_content">
                        <p>Excellent Behaviour and service by Mr Naresh. Got my washing machine repaired. Highly recommended.</p>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="clearfix" />
              
              </div>
            </div>
          </div>
        </div>
      </div>
 
    
     </>
  }
}
