const data = [
    {
        id:1,
        service:"Air Conditioner",
        category:"repair",
        title:"GAS LEAKAGE COVER AND REFFILLING",
        image:"../assets/images/service_detail/ac1.png",
        desc: "AC gas refill is required when there is a leak from the coils or the unit is blowing hot air instead of cold air. A thorough inspection is done to check if gas filling is required. After leaking points are identified it is fixed and gas is filled. An air conditioner uses both physical and chemical phenomena that are gas to cool a place effectively.",
        details:"",
        rating:"4.5",
        reviewcount:"200",
        price:"2499.00",
    },
    {
        id:2,
        service:"Air Conditioner",
        category:"repair",
        title:"AC CHECK UP",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Air Conditioner compressors sometimes develop issues over a period of time due to wear and tear, so it's important to have a company you can trust to keep your AC unit in top condition. We provide excellent compressor repair and replacement services and maintenance services.",
        details:"<p>What Is An AC Compressor?</p><p>A compressor is a AC unit that helps to circulate refrigerant through an air conditioning system. Refrigerant is responsible for cooling the air, and the compressor ensures that it flows evenly throughout air conditioning system.</p>",
        rating:"4.5",
        reviewcount:"200",
        price:"199.00",
    },
    {
        id:3,
        service:"Air Conditioner",
        category:"service",
        title:"WINDOW AC JET CLEANING  SERVICE",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Deep cleaning of dust particles for fresh air, filter coils, ac parts with water and foam using jet technology. All elements checked for optimum functioning and performance.",
        details:"<p>How AC service is done?<br/> &bull; Complete check up of the AC unit<br />&bull; Deep Cleaning &nbsp;clogged air filter, coil etc<br />&bull; Air distribution check<br />&bull; Thermostat Test, calibrate and level<br />&bull; Condenser Coil inspection<br />&bull; Refrigerant operating pressures check<br />&bull; Cooling temperature functionality<br />&bull; Finally is it working as new AC</p>",
        rating:"4.9",
        reviewcount:"200",
        price:"299.00",
    },
    {
        id:4,
        service:"Air Conditioner",
        category:"service",
        title:"WINDOW AC FOAM JET CLEANING  SERVICE",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Deep cleaning of dust particles for fresh air, filter coils, ac parts with water and foam using jet technology. All elements checked for optimum functioning and performance.",
        details:"<p>How AC service is done?<br/> &bull; Complete check up of the AC unit<br />&bull; Deep Cleaning &nbsp;clogged air filter, coil etc<br />&bull; Air distribution check<br />&bull; Thermostat Test, calibrate and level<br />&bull; Condenser Coil inspection<br />&bull; Refrigerant operating pressures check<br />&bull; Cooling temperature functionality<br />&bull; Finally is it working as new AC</p>",
        rating:"4.9",
        reviewcount:"200",
        price:"499.00",
    },
    {
        id:113,
        service:"Air Conditioner",
        category:"service",
        title:"SPLIT AC JET CLEANING SERVICE",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Deep cleaning of dust particles for fresh air, filter coils, ac parts with water and foam using jet technology. All elements checked for optimum functioning and performance.",
        details:"<p>How AC service is done?<br/> &bull; Complete check up of the AC unit<br />&bull; Deep Cleaning &nbsp;clogged air filter, coil etc<br />&bull; Air distribution check<br />&bull; Thermostat Test, calibrate and level<br />&bull; Condenser Coil inspection<br />&bull; Refrigerant operating pressures check<br />&bull; Cooling temperature functionality<br />&bull; Finally is it working as new AC</p>",
        rating:"4.9",
        reviewcount:"200",
        price:"499.00",
    },
    {
        id:114,
        service:"Air Conditioner",
        category:"service",
        title:"SPLIT AC FOAM JET CLEANING SERVICE",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Deep cleaning of dust particles for fresh air, filter coils, ac parts with water and foam using jet technology. All elements checked for optimum functioning and performance.",
        details:"<p>How AC service is done?<br/> &bull; Complete check up of the AC unit<br />&bull; Deep Cleaning &nbsp;clogged air filter, coil etc<br />&bull; Air distribution check<br />&bull; Thermostat Test, calibrate and level<br />&bull; Condenser Coil inspection<br />&bull; Refrigerant operating pressures check<br />&bull; Cooling temperature functionality<br />&bull; Finally is it working as new AC</p>",
        rating:"4.9",
        reviewcount:"200",
        price:"799.00",
    },
    {
        id:5,
        service:"Air Conditioner",
        category:"install",
        title:"WINDOW AC INSTALLATION",
        image:"../assets/images/service_detail/ac1.png",
        desc: "AC installation measuring the wall, drilling to fix AC stand in window, Connect inlet AC assembly, electricity supply to Air Conditioner and check window AC is functioning properly.",
        details:"<p>What are the excluded fixtures chargeable?</p><p>&bull; Copper wire, plastic channels and pipes (Charges as per rate card)<br />&bull; AC stand &nbsp;and fasteners<br />&bull; Carpentry and Masonry work<br />&bull; &nbsp;Plastering and filling work<br />",
        rating:"4.7",
        reviewcount:"200",
        price:"499.00",
    },
    {
        id:6,
        service:"Air Conditioner",
        category:"install",
        title:"WINDOW AC UNINSTALLATION",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Disconnect electricity supply to air conditioner; dismantle inlet AC assembly form air Conditioner. Dismantle air Conditioner from window and pack AC securely.",
        details:"",
        rating:"4.7",
        reviewcount:"200",
        price:"249.00",
    },
    {
        id:7,
        service:"Air Conditioner",
        category:"install",
        title:"SPLIT AC INSTALLATION",
        image:"../assets/images/service_detail/ac1.png",
        desc: "AC installation measuring the wall, drilling to fix AC stand in window, Connect inlet AC assembly, electricity supply to Air Conditioner and check window AC is functioning properly.",
        details:"<p>What are the excluded fixtures chargeable?</p><p>&bull; Copper wire, plastic channels and pipes (Charges as per rate card)<br />&bull; AC stand &nbsp;and fasteners<br />&bull; Carpentry and Masonry work<br />&bull; &nbsp;Plastering and filling work<br />",
        rating:"4.5",
        reviewcount:"200",
        price:"1299.00",
    },
    {
        id:8,
        service:"Air Conditioner",
        category:"install",
        title:"SPLIT AC UNINSTALLATION",
        image:"../assets/images/service_detail/ac1.png",
        desc: "Disconnect electricity supply to air conditioner; dismantle inlet AC assembly form air Conditioner. Dismantle outdoor unit, air Conditioner from wall and pack AC securely.",
        details:"",
        rating:"4.9",
        reviewcount:"200",
        price:"599.00",
    },
    
    {
        id:11,
        service:"TV",
        category:"repair",
        title:"TELEVISION REPAIR",
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.230.",
        details:"When should I repair my TV? epair and service is required when your TV has these issues No picture Display, Colour Issue, Haze Image, Loud Sound, Half Image, No Display, Lines on TV and TV not switching on Snappy service specialist will fix your TV in minutes.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:12,
        service:"TV",
        category:"install",
        title:'TELEVISION INSTALLATION UPTO 26"',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.249.",
        details:"What is the process of TV Installation?<br/>  Important procedure to be followed while mounting and installing LED or LCD TV is correct wall selection, accurate distance from ground, distance between seating and screen, correct viewing angle.  Wall mounting saves floor space that would have otherwise been occupied by the TV stand.  TV installation includes fixing bracket, TV mounting in the bracket.",
        rating:"4.7",
        reviewcount:"250",
        price:"249.00",
    },
     {
        id:13,
        service:"TV",
        category:"install",
        title:'TELEVISION INSTALLATION UPTO 43"',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.349.",
        details:"What is the process of TV Installation?<br/>  Important procedure to be followed while mounting and installing LED or LCD TV is correct wall selection, accurate distance from ground, distance between seating and screen, correct viewing angle.  Wall mounting saves floor space that would have otherwise been occupied by the TV stand.  TV installation includes fixing bracket, TV mounting in the bracket.",
        rating:"4.7",
        reviewcount:"250",
        price:"349.00",
    },
     {
        id:14,
        service:"TV",
        category:"install",
        title:'TELEVISION INSTALLATION UPTO  55"',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.399.",
        details:"What is the process of TV Installation?<br/>  Important procedure to be followed while mounting and installing LED or LCD TV is correct wall selection, accurate distance from ground, distance between seating and screen, correct viewing angle.  Wall mounting saves floor space that would have otherwise been occupied by the TV stand.  TV installation includes fixing bracket, TV mounting in the bracket.",
        rating:"4.7",
        reviewcount:"250",
        price:"399.00",
    },
    
     {
        id:16,
        service:"TV",
        category:"install",
        title:'TELEVISION INSTALLATION UPTO 65"',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.349.",
        details:"What is the process of TV Installation?<br/>  Important procedure to be followed while mounting and installing LED or LCD TV is correct wall selection, accurate distance from ground, distance between seating and screen, correct viewing angle.  Wall mounting saves floor space that would have otherwise been occupied by the TV stand.  TV installation includes fixing bracket, TV mounting in the bracket.",
        rating:"4.7",
        reviewcount:"250",
        price:"450.00",
    },
    {
        id:20,
        service:"TV",
        category:"install",
        title:'TELEVISION UNINSTALLATION UPTO 43"',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"What is the process of TV Uninstallation?<br/> Television unistallation has to done with at most care so our trained service engineer first removes all cables from the TV, removes the protection which is usually secured to the mount by a protective element, lift the TV off the mount , remove the mount and finally close the holes and your TV Safely.",
        rating:"4.7",
        reviewcount:"250",
        price:"199.00",
    },
     {
        id:18,
        service:"TV",
        category:"install",
        title:'TELEVISION UNINSTALLATION UPTO 65',
        image:"../assets/images/service_detail/tv-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.299.",
        details:"What is the process of TV Uninstallation?<br/> Television unistallation has to done with at most care so our trained service engineer first removes all cables from the TV, removes the protection which is usually secured to the mount by a protective element, lift the TV off the mount , remove the mount and finally close the holes and your TV Safely.",
        rating:"4.7",
        reviewcount:"250",
        price:"299.00",
    },
     
    {
        id:9,
        service:"Washing Machine",
        category:"repair",
        title:"Semi Automatic Washing Machine Top Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Washing Machine won't start, Dirty laundry, Squeaking and loud noise, Incomplete spin cycles, Errors display sensors, Drum not turning, Water doesn’t drain, water won't fill in drum",
        details:"What does the washing machine repair and service include?<br/>Once you have booked your service online, call, whatsapp our skilled washing machine repair technicians will visit your home make a diagnosis, fix the problem, and perform a test run to make sure the washing machine is performing optimally. <br/>Is spare included in service cost?<br/>No all spare parts used are additional charges as per the approved price list.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:10,
        service:"Washing Machine",
        category:"repair",
        title:"Fully Automatic Washing Machine Top Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Washing Machine won't start, Dirty laundry, Squeaking and loud noise, Incomplete spin cycles, Errors display sensors, Drum not turning, Water doesn’t drain, water won't fill in drum",
        details:"What does the washing machine repair and service include?<br/>Once you have booked your service online, call, whatsapp our skilled washing machine repair technicians will visit your home make a diagnosis, fix the problem, and perform a test run to make sure the washing machine is performing optimally. <br/>Is spare included in service cost?<br/>No all spare parts used are additional charges as per the approved price list.",
        rating:"4.5",
        reviewcount:"350",
        price:"249.00",
    },
     {
        id:22,
        service:"Washing Machine",
        category:"install",
        title:"Semi Automatic Washing Machine Top Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Depending on your living place, you may be limited in your options of places to put a washing machine snappy skilled service engineer chooses the right spot, measure the area to ensure proper fit, adjust the levelling feet, fixing to the tap outlet, connecting the water supply to the hose, set up drainage hose, plug in to electric outlet and test run to see your washing machine is washing clothes perfectly. ",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.149.",
        rating:"4.5",
        reviewcount:"250",
        price:"149.00",
    },
    {
        id:25,
        service:"Washing Machine",
        category:"install",
        title:"Semi Automatic Washing Machine Front Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Depending on your living place, you may be limited in your options of places to put a washing machine snappy skilled service engineer chooses the right spot, measure the area to ensure proper fit, adjust the levelling feet, fixing to the tap outlet, connecting the water supply to the hose, set up drainage hose, plug in to electric outlet and test run to see your washing machine is washing clothes perfectly. ",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.149.",
        rating:"4.5",
        reviewcount:"250",
        price:"149.00",
    },
    {
        id:23,
        service:"Washing Machine",
        category:"install",
        title:"Fully Automatic Washing Machine Top Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Depending on your living place, you may be limited in your options of places to put a washing machine snappy skilled service engineer chooses the right spot, measure the area to ensure proper fit, adjust the levelling feet, fixing to the tap outlet, connecting the water supply to the hose, set up drainage hose, plug in to electric outlet and test run to see your washing machine is washing clothes perfectly. ",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.149.",
        rating:"4.5",
        reviewcount:"350",
        price:"149.00",
    },
     {
        id:24,
        service:"Washing Machine",
        category:"install",
        title:"Fully Automatic Washing Machine Front Load (All Brands)",
        image:"../assets/images/service_detail/w-1.png",
        desc: "Depending on your living place, you may be limited in your options of places to put a washing machine snappy skilled service engineer chooses the right spot, measure the area to ensure proper fit, adjust the levelling feet, fixing to the tap outlet, connecting the water supply to the hose, set up drainage hose, plug in to electric outlet and test run to see your washing machine is washing clothes perfectly.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.149.",
        rating:"4.5",
        reviewcount:"350",
        price:"149.00",
    },
     {
        id:26,
        service:"Refrigerator",
        category:"service",
        title:"Refrigerator – Fridge Service Single Door (All Brands) ",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:27,
        service:"Refrigerator",
        category:"service",
        title:"Refrigerator – Fridge Service Double Door(All Brands) ",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:28,
        service:"Refrigerator",
        category:"service",
        title:"Refrigerator – Fridge Service Side By Side Door( All Brands)",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:29,
        service:"Refrigerator",
        category:"repair",
        title:"Refrigerator – Fridge Repair Single Door (All Brands) ",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:30,
        service:"Refrigerator",
        category:"repair",
        title:"Refrigerator – Fridge Repair Double Door(All Brands) ",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:31,
        service:"Refrigerator",
        category:"repair",
        title:"Refrigerator – Fridge Repair Side By Side Door( All Brands)",
        image:"../assets/images/service_detail/f-2.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.199.",
        details:"When you’re Refrigerator – Fridge needs repair?<br/> Visible refrigerator problem like a burnt out light bulb or a leaky water line are so easy for anyone to see. But other problems are much harder to diagnose. For example, if your fridge is not cooling, the problem might be any one of 5 common problems including dirty condenser coils, a bad evaporator fan motor, a bad condenser fan motor, a faulty thermostat, or a broken compressor. Snappy most trusted Refrigerator service provider in Delhi Noida Ghaziabad and Faridabad.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:32,
        service:"Solar Appliances",
        category:"install",
        title:"Solar Appliances - Residential- Commercial",
        image:"../assets/images/service_detail/solar-1.png",
        desc: "Solar panel repair involves the full solar panel of fixes, upgrades, and replacements required to bring a broken or underperforming installation back online. In some cases, it’s just a single solar panel that needs repairs. Other times, technicians may need to repair or replace other components in the system, including inverters, batteries, or electrical wiring. ",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"1212",
        price:"99.00",
    },
    {
        id:33,
        service:"Solar Appliances",
        category:"repair",
        title:"Solar Appliances - Residential- Commercial",
        image:"../assets/images/service_detail/solar-1.png",
        desc: "Snappy is proud to offer exceptional high-quality solar power installation for residential, commercial and agricultural properties in Delhi NCR. As fully qualified solar professionals install a solar power system that is uniquely designed for your needs. With custom designs, turnkey solar panel installation and exclusive monitoring and maintenance, we offer everything you need to experience solar power at its best. ",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    
     {
        id:35,
        service:"Chimney",
        category:"repair",
        title:"CHIMNEY REPAIR",
        image:"../assets/images/service_detail/ch-1.png",
        desc: "Suction not working, Fan is rotating slowly, Too much noise, Electric chimney is not running properly, Touch or Push Button Control Panel Not Working, Fan & Light not working, Heavy vibration,No Display on the Chimney",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"250",
        price:"149.00",
    },
     {
        id:36,
        service:"Chimney",
        category:"install",
        title:"CHIMNEY INSTALLATION",
        image:"../assets/images/service_detail/ch-1.png",
        desc: "Chimney installation procedures Chimney’s size, space between chimney and stove, duct of the chimney, hole drilling on the wall for the chimney duct, connecting to electric power point inspecting chimney and ensuring smoke exhausted from the kitchen.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"250",
        price:"499.00",
    },
    {
        id:92,
        service:"Chimney",
        category:"service",
        title:"CHIMNEY CLEANING BASIC",
        image:"../assets/images/service_detail/ch-1.png",
        desc: "It is recommended for best performance of your chimney service has to be done twice a year  chimney filter cleaning, cleaning of outer body and fan wings, cassette filter oil/grease particles cleaning, chimney duct cleaning and Oiling of motors. Snappy brings the best chimney service",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"250",
        price:"399.00",
    },
     {
        id:110,
        service:"Chimney",
        category:"service",
        title:"CHIMNEY CLEANING DEEP",
        image:"../assets/images/service_detail/ch-1.png",
        desc: "It is recommended for best performance of your chimney service has to be done twice a year  chimney filter cleaning, cleaning of outer body and fan wings, cassette filter oil/grease particles cleaning, chimney duct cleaning and Oiling of motors. Snappy brings the best chimney service",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"250",
        price:"799.00",
    },
     {
        id:37,
        service:"Chimney",
        category:"install",
        title:"CHIMNEY UNINSTALLATION",
        image:"../assets/images/service_detail/ch-1.png",
        desc: "Disconnect Power Supply, dismantle the exhaust filter, unmount the chimney hood, remove chimney duct from wall and pack safely.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
     {
        id:38,
        service:"ROSerivce",
        category:"install",
        title:"RO INSTALLATION",
        image:"../assets/images/service_detail/ro-1.png",
        desc: "RO Purifier not working, water leakage, foul odour or bad taste of water, auto shut off Failure, low water flow or storage tank taking more time to fill, noise in RO purifiers, excess amount of reject water.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.9",
        reviewcount:"1212",
        price:"149.00",
    },
    {
        id:39,
        service:"ROSerivce",
        category:"install",
        title:"RO UNINSTALLATION",
        image:"../assets/images/service_detail/ro-1.png",
       desc: "RO Purifier not working, water leakage, foul odour or bad taste of water, auto shut off Failure, low water flow or storage tank taking more time to fill, noise in RO purifiers, excess amount of reject water.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    {
        id:40,
        service:"ROSerivce",
        category:"repair",
        title:"RO REPAIR",
        image:"../assets/images/service_detail/ro-1.png",
        desc: "RO Purifier not working, water leakage, foul odour or bad taste of water, auto shut off Failure, low water flow or storage tank taking more time to fill, noise in RO purifiers, excess amount of reject water.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:41,
        service:"ROSerivce",
        category:"service",
        title:"RO SERVICE",
        image:"../assets/images/service_detail/ro-1.png",
        desc: "It is recommended for pure drinking water you service your RO every six months change filter candle, carbon filter, clean all RO chambers. We use company original genuine parts and check for purity. ",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
     {
        id:42,
        service:"Electrical Motor",
        category:"repair",
        title:"Electrical Motor Repair",
        image:"../assets/images/service_detail/motor-1.png",
        desc: "Motor not starting, High-frequency noise, Sparks in motor, Pitting, Over heating, Motor damage",
        details:"<p>What is electric Motor AC/DC Drive process? To begin the repair on an AC/DC drive a visual inspection is performed. Next meters and signature analysis devices are used to component test. Capacitors, drive and output transistors, power supplies, SCR&rsquo;s, and firing circuits are checked. Common failure parts are tested and replaced as necessary drive is then powered up.<br /><br />While powered up the power supplies are checked as are the outputs for proper wave form.<br /<br />>A dummy load is applied to the drive and the output waveform is checked with an oscilloscope<br />If proper output is seen the dummy load is removed and the drive is fully load tested with the proper motor and with a dynamometer, it is scoped again for proper wave form entire unit is cleaned and retested. Please note<br />. &nbsp;Price quote after inspection<br />. &nbsp;Repairs and spare parts chargeable as agreed<br /></p>",
        rating:"4.7",
        reviewcount:"250",
        price:"99.00",
    },
     {
        id:43,
        service:"Electrical Motor",
        category:"install",
        title:"Electrical Motor Rewinding",
        image:"../assets/images/service_detail/motor-1.png",
        desc: "Sparks in motor, Motor damage, Smoke from motor, Burning smell, Motor not starting ",
        details:"<p>How is motor Rewinding done?</p><p>We strip the windings, taking care to preserve their structural integrity. Technicians carefully record and measure the wire size, span, turns and number of conductors within the motor coils as well as grouping and internal wiring. We burn out coils using a pollution-controlled furnace at carefully regulated temperatures. We remove bad coils and blast the core clean using non-destructive media to prevent excessive efficiency losses. Motors that are highly contaminated with oil or grease within the laminations receive pressure-cleaning at a high temperature with an industrial alkaline-based degreaser.</p><p>A winding technician then makes new coils using only VFD-rated magnet wire. VFD-shielded wire is essential because motors that run with a VFD are subject to more electrical stress than motors that use a starter. We install H-class insulation and then dip and bake the windings. Motor windings undergo testing with an AWA analyzer both before and after the rewind process.</p><p>Please note<br />. &nbsp;Price quote after inspection<br />. &nbsp;Repairs and spare parts chargeable as agreed<br /></p>",
        rating:"4.7",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:44,
        service:"Microwave",
        category:"repair",
        title:"Microwave Repair & Service (All Brands)",
        image:"../assets/images/service_detail/m-1.png",
        desc: "Microwave not heating, No Power, Sparks can be seen, Microwave turntable not Rotating,Control Panel not working",
        details:"What is process of repairing microwave?<br/> We follow strict prescribed way of microwave repairs by unplugging power and removing Moving Parts. Back Cover opened for inspection, fuse check, door switches, capacitor and replace faulty parts with new genuine spare parts. Checking your microwave is working perfectly.",
        rating:"4.5",
        reviewcount:"250",
        price:"199.00",
    },
    {
        id:111,
        service:"Microwave",
        category:"repair",
        title:"Microwave Checking",
        image:"../assets/images/service_detail/m-1.png",
        desc: "Microwave not heating, No Power, Sparks can be seen, Microwave turntable not Rotating,Control Panel not working",
        details:"What is process of repairing microwave?<br/> We follow strict prescribed way of microwave repairs by unplugging power and removing Moving Parts. Back Cover opened for inspection, fuse check, door switches, capacitor and replace faulty parts with new genuine spare parts. Checking your microwave is working perfectly.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:45,
        service:"Geyser",
        category:"install",
        title:"GEYSER INSTALLATION UPTO 15LTR",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"",
        rating:"4.9",
        reviewcount:"1212",
        price:"199.00",
    },
    {
        id:46,
        service:"Geyser",
        category:"install",
        title:"GEYSER INSTALLATION UPTO 25 LTR",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"",
        rating:"4.5",
        reviewcount:"350",
        price:"299.00",
    },
     {
        id:47,
        service:"Geyser",
        category:"install",
        title:"GEYSER UNINSTALLATION UPTO 15 LTR",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"",
        rating:"4.5",
        reviewcount:"350",
        price:"149.00",
    },
     {
        id:48,
        service:"Geyser",
        category:"install",
        title:"GEYSER UNINSTALLATION UPTO 25 LTR",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"",
        rating:"4.5",
        reviewcount:"350",
        price:"199.00",
    },
     {
        id:49,
        service:"Geyser",
        category:"install",
        title:"Storage Geyser – Solar (All Brands)",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"How to install solar geyser?<br/> Snappy methodology of solar geyser installation mount the solar collectors on the roof; Install the solar storage tank and heat exchanger next to conventional water heater, Install the piping and pump for the glycol loop, Install the water piping, Install the controls, fill the system, Insulate the water and glycol lines, check if geyser is functioning perfectly we guarantee that you will be 100% satisfaction with installation. ",
        rating:"4.5",
        reviewcount:"350",
        price:"249.00",
    },
     {
        id:50,
        service:"Geyser",
        category:"install",
        title:"Instant Geyser – Solar ( All Brands)",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Geyser installation should be done as per guidelines by qualified electrician keeping in mind some important aspects correct height from ground, sufficient space, firm wall structure, unblocked water pipes, and safe electric connection. Incorrect installations can cost you a lot in repair and run a huge safety risk. Snappy electrician you’re most trusted service.",
        details:"How to install solar geyser?<br/> Snappy methodology of solar geyser installation mount the solar collectors on the roof; Install the solar storage tank and heat exchanger next to conventional water heater, Install the piping and pump for the glycol loop, Install the water piping, Install the controls, fill the system, Insulate the water and glycol lines, check if geyser is functioning perfectly we guarantee that you will be 100% satisfaction with installation. ",
        rating:"4.5",
        reviewcount:"350",
        price:"249.00",
    },
     {
        id:51,
        service:"Geyser",
        category:"repair",
        title:"GEYSER REPAIR (All Brands)",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Heating Issue, Leakage from the Tank, No Power, Faulty Temperature, Rust Coloured Water, Unusual Sound",
        details:"Can geyser be repaired? <br/>Yes, geyser can be repaired. There are some small problems which you can repair yourself. For other problems, call geyser experts snappy to repair your geyser.",
        rating:"4.6",
        reviewcount:"1212",
        price:"199.00",
    },
    {
        id:52,
        service:"Geyser",
        category:"repair",
        title:"GEYSER SCALLING (All Brands)",
        image:"../assets/images/service_detail/g-1.png",
        desc: "Heating Issue, Leakage from the Tank, No Power, Faulty Temperature, Rust Coloured Water, Unusual Sound",
        details:"Can geyser be repaired? <br/>Yes, geyser can be repaired. There are some small problems which you can repair yourself. For other problems, call geyser experts snappy to repair your geyser.",
        rating:"4.5",
        reviewcount:"350",
        price:"299.00",
    },
     
    {
        id:57,
        service:"Home Automation",
        category:"install",
        title:"Centralised Home Automation",
        image:"../assets/images/service_detail/ha.png",
        desc: "Smart home automation technologies provide a real-time overview of your home’s security, energy usage and can be programmed to automatically respond to certain conditions. From smart motion detectors that automatically switch on/off appliances when people are present in your home, when out of home you can control all appliances on your finger tips. These features will help you cut down on electricity usage and control over your home appliances and devices.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.9",
        reviewcount:"1212",
        price:"99.00",
    },
    {
        id:58,
        service:"Home Automation",
        category:"install",
        title:"Intelligent Lighting",
        image:"../assets/images/service_detail/ha.png",
        desc: "Have you ever accidentally left your lights on when rushing out to an event or appointment? Are you tired of manually turning your balcony lights on and off every single evening? Do you want to be able to dim your lights without getting up off your couch? With intelligent home lighting installation, you will be able to control and adjust your lights from the palm of your hand – even when you are not home. These smart features are both convenient and energy-efficient – saving you money on monthly electricity bills.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    {
        id:59,
        service:"Home Automation",
        category:"install",
        title:"Telephone Wiring",
        image:"../assets/images/service_detail/ha.png",
        desc: "No matter the size or nature of your home or business, you need to establish a communications network that is fast, reliable, and easy to manage and maintain. But ensuring that your network operates efficiently, we have the tools and know-how to make sure that your cable systems are configured correctly and optimized to provide maximum efficiency. We’re equipped to help you with every facet of designing, installing, and configuring a cabled network system. ",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
     {
        id:60,
        service:"Home Automation",
        category:"install",
        title:"Internet Networking  ",
        image:"../assets/images/service_detail/ha.png",
        desc: "A computer network is an interconnection of multiple systems that enables communication, file and resource sharing. Our IT professionals are trained to do your computer network services from cabling, routing & switching, security appliances & firewalls, WAN optimization that keeps computers running smoothly without major downtime.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    {
        id:61,
        service:"Home Automation",
        category:"install",
        title:"HVAC ",
        image:"../assets/images/service_detail/ha.png",
        desc: "Homes and businesses depend on air conditioning, heating, and other indoor environment systems to stay comfortable and safe. From homes, petrol bunks to high rise buildings; your indoor environment system has a huge impact on your monthly operating costs. Not to mention the impact that it has on your family, employees, tenants, and customers you need a licensed and certified electrical contractor to install or repair your HVAC.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    {
        id:62,
        service:"Home Automation",
        category:"repair",
        title:"Centralised Home Automation",
        image:"../assets/images/service_detail/ha.png",
        desc: "Smart home automation technologies provide a real-time overview of your home’s security, energy usage and can be programmed to automatically respond to certain conditions. From smart motion detectors that automatically switch on/off appliances when people are present in your home, when out of home you can control all appliances on your finger tips. These features will help you cut down on electricity usage and control over your home appliances and devices.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:63,
        service:"Home Automation",
        category:"repair",
        title:"Home Theatre",
        image:"../assets/images/service_detail/ha.png",
        desc: "With a professionally installed smart home theatre, you can enjoy the luxuries and comforts of a hi-tech entertainment setup at home. Our expert technicians can integrate numerous home theatre devices into your home automation system. We can make it possible to control and program your lights, speakers, curtains, recliner seats, and TV with a simple voice command or touch of a button. Let us help you create an immersive and dynamic home viewing experience today",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:64,
        service:"Home Automation",
        category:"repair",
        title:"Audio – Visual",
        image:"../assets/images/service_detail/ha.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.99.",
        details:"",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:65,
        service:"Home Automation",
        category:"repair",
        title:"Intelligent Lighting",
        image:"../assets/images/service_detail/ha.png",
        desc: "Have you ever accidentally left your lights on when rushing out to an event or appointment? Are you tired of manually turning your balcony lights on and off every single evening? Do you want to be able to dim your lights without getting up off your couch? With intelligent home lighting installation, you will be able to control and adjust your lights from the palm of your hand – even when you are not home. These smart features are both convenient and energy-efficient – saving you money on monthly electricity bills.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:66,
        service:"Home Automation",
        category:"repair",
        title:"HVAC ",
        image:"../assets/images/service_detail/ha.png",
        desc: "Homes and businesses depend on air conditioning, heating, and other indoor environment systems to stay comfortable and safe. From homes, petrol bunks to high rise buildings; your indoor environment system has a huge impact on your monthly operating costs. Not to mention the impact that it has on your family, employees, tenants, and customers you need a licensed and certified electrical contractor to install or repair your HVAC.",
        details:"Please note:<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00",
    },
    {
        id:67,
        service:"Electrical Services",
        category:"install",
        title:"Wiring ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "We provide unrivalled electrical wiring service, switch installing, electrical cabling and affiliated devices, including circuit breaker & boards, switches, sockets, outlets, ceiling fans, and lighting fixtures. Snappy highly skilled electricians can safely and efficiently do any wiring your home or commercial establishment needs.",
        details:"<p>Can I Run My Own Electrical Wiring? It can:<br />&bull; Put you at risk of injury or death by consequence of severe electrical shock<br />&bull; Result in a structural fire due to faulty wiring<br />&bull; Nullify your home insurance policy<br />We highly recommend letting a licensed electrician wire/rewire circuits, install/replace power outlets, install/replace ceiling fans and light switches, and carry out any complex electrical projects. Snappy electricians go through years of training for a reason &ndash; to provide low-risk electrical services.<br />&nbsp;</p>",
        rating:"4.9",
        reviewcount:"1212",
        price:"99.00*",
    },
    {
        id:68,
        service:"Electrical Services",
        category:"install",
        title:"ReWiring ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "It is important to update and maintain electrical wiring circuitry. At snappy services, we are ready to help households adapt to higher power demands, renew faulty systems and ensure their rewiring meets government electricity board regulations. Our licensed electricians can safely and efficiently rewire any home or commercial complex.",
        details:"What factors Involved in Rewiring a house or commercial complex?<br/> If your home is old and has aluminium wiring, we advise promptly rewiring your house. Our friendly technicians can inspect your current wiring situation and offer expert tips on what, if any rewiring is needed. Factors size and age of your house, ease of access to wiring, materials used, work involved, Individual or Various Electrical Work to Be Done.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:69,
        service:"Electrical Services",
        category:"install",
        title:"Residential Electrical",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.99.",
        details:"",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:70,
        service:"Electrical Services",
        category:"install",
        title:"SWITCH SOCKET INSTALLATION",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Whether you’re installing new appliances or remodeling your home, you may need to repair or install new electrical switches or outlets. Modern electrical outlets provide flexible, safe, and reliable power delivery. Snappy makes sure your home is equipped with the right electric receptacles to ensure that your home is safe.",
        details:"If you’ve noticed that the electrical outlets in your home aren’t working as well as they use to, or they aren’t able to hold the plug, it may be time to repair or replace it. Having malfunctioning outlets can be a serious shock and fire hazard. If you notice that your outlets spark when you plug or unplug an appliance, it is a sign to install a new outlet. You mustn’t try to repair or replace your outlets without the help of a professional.",
        rating:"4.5",
        reviewcount:"350",
        price:"159.00*",
    },
    {
        id:71,
        service:"Electrical Services",
        category:"install",
        title:"Tripping or Fluctuation Electricity ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "As your reliable home electricians, we provide electrical panel board repair when your appliances aren’t running at full capacity or your lights Tripping or Fluctuation unexpectedly. There are several other signs that you may need your electrical panel repaired, including consistently tripped breakers, overheated conductors, and so much more. Our service engineer will evaluate the problem and present options for you.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. ",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:72,
        service:"Electrical Services",
        category:"install",
        title:"Light Fitting",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Whether you’re trying to set a mood on your backyard or to provide adequate light sources throughout your home, lighting plays a significant role in your home’s function and aesthetic. We can deliver lighting installation and repair solutions that give you the peace of mind you deserve in and outside your home that fits your lifestyle at a price you can afford",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
     {
        id:73,
        service:"Electrical Services",
        category:"install",
        title:"Electrical Power Supply Line",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Electric power distribution is the final stage in the delivery of electric power; it carries electricity from the transmission system to individual consumers. Distribution substations connect to the transmission system and lower the transmission voltage to medium voltage ranging between 2 kV and 35 kV with the use of transformers. Snappy highly qualified electrician can install/repair power supply line for residential or commercial.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    
     {
        id:74,
        service:"Electrical Services",
        category:"repair",
        title:"Wiring ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "We provide unrivalled electrical wiring service, switch installing, electrical cabling and affiliated devices, including circuit breaker & boards, switches, sockets, outlets, ceiling fans, and lighting fixtures. Snappy highly skilled electricians can safely and efficiently do any wiring your home or commercial establishment needs.",
        details:"<p>Can I Run My Own Electrical Wiring? It can:<br />&bull; Put you at risk of injury or death by consequence of severe electrical shock<br />&bull; Result in a structural fire due to faulty wiring<br />&bull; Nullify your home insurance policy<br />We highly recommend letting a licensed electrician wire/rewire circuits, install/replace power outlets, install/replace ceiling fans and light switches, and carry out any complex electrical projects. Snappy electricians go through years of training for a reason &ndash; to provide low-risk electrical services.<br />&nbsp;<br/>Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Visit Charges Rs.99</p>",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00*",
    },
     {
        id:75,
        service:"Electrical Services",
        category:"repair",
        title:"ReWiring ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "It is important to update and maintain electrical wiring circuitry. At snappy services, we are ready to help households adapt to higher power demands, renew faulty systems and ensure their rewiring meets government electricity board regulations. Our licensed electricians can safely and efficiently rewire any home or commercial complex.",
        details:"What factors Involved in Rewiring a house or commercial complex?<br/> If your home is old and has aluminium wiring, we advise promptly rewiring your house. Our friendly technicians can inspect your current wiring situation and offer expert tips on what, if any rewiring is needed. Factors size and age of your house, ease of access to wiring, materials used, work involved, Individual or Various Electrical Work to Be Done.<br/>Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Visit Charges Rs.99",
        rating:"4.8",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:76,
        service:"Electrical Services",
        category:"repair",
        title:"Electric Socket, Switches, Switchboard ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Whether you’re installing new appliances or remodeling your home, you may need to repair or install new electrical switches or outlets. Modern electrical outlets provide flexible, safe, and reliable power delivery. Snappy makes sure your home is equipped with the right electric receptacles to ensure that your home is safe.",
        details:"If you’ve noticed that the electrical outlets in your home aren’t working as well as they use to, or they aren’t able to hold the plug, it may be time to repair or replace it. Having malfunctioning outlets can be a serious shock and fire hazard. If you notice that your outlets spark when you plug or unplug an appliance, it is a sign to install a new outlet. You mustn’t try to repair or replace your outlets without the help of a professional. <br/>Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Visit Charges Rs.99",
        rating:"4.9",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:77,
        service:"Electrical Services",
        category:"repair",
        title:"Tripping or Fluctuation Electricity ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "As your reliable home electricians, we provide electrical panel board repair when your appliances aren’t running at full capacity or your lights Tripping or Fluctuation unexpectedly. There are several other signs that you may need your electrical panel repaired, including consistently tripped breakers, overheated conductors, and so much more. Our service engineer will evaluate the problem and present options for you.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. ",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
     {
        id:78,
        service:"Electrical Services",
        category:"repair",
        title:"Light Fitting",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Whether you’re trying to set a mood on your backyard or to provide adequate light sources throughout your home, lighting plays a significant role in your home’s function and aesthetic. We can deliver lighting installation and repair solutions that give you the peace of mind you deserve in and outside your home that fits your lifestyle at a price you can afford",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:79,
        service:"Electrical Services",
        category:"service",
        title:"Electrical Maintenance ",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.99.",
        details:"",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00*",
    },
     {
        id:80,
        service:"Electrical Services",
        category:"repair",
        title:"Electrical Earthing Inspection",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed. Inspection fee Rs.99.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00*",
    },
    {
        id:91,
        service:"Electrical Services",
        category:"repair",
        title:"Electrical Power Supply Line",
        image:"../assets/images/service_detail/ec-1.png",
        desc: "Electric power distribution is the final stage in the delivery of electric power; it carries electricity from the transmission system to individual consumers. Distribution substations connect to the transmission system and lower the transmission voltage to medium voltage ranging between 2 kV and 35 kV with the use of transformers. Snappy highly qualified electrician can install/repair power supply line for residential or commercial.",
        details:"Please note: Price quote after inspection. Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"350",
        price:"99.00*",
    },
    {
        id:81,
        service:"Fan",
        category:"install",
        title:"CELLING FAN INSTALLATION",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:82,
        service:"Fan",
        category:"install",
        title:"AXUST FAN INSTALLATION",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"119.00",
    },
    {
        id:82,
        service:"Fan",
        category:"install",
        title:"DECORATIVE FAN  INSTALLATION",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"399.00",
    },
    {
        id:115,
        service:"Fan",
        category:"install",
        title:"FAN UNINSTALLATION",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"79.00",
    },
    {
        id:116,
        service:"Fan",
        category:"install",
        title:"WALL FAN INSTALLATION ",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:82,
        service:"Fan",
        category:"repair",
        title:"FAN REPAIR",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:83,
        service:"Fan",
        category:"repair",
        title:"FAN REGULATOR REPLACE",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"79.00",
    },
    {
        id:84,
        service:"Fan",
        category:"repair",
        title:"FAN REPLACE",
        image:"../assets/images/service_detail/fan-1.png",
        desc: "We’re residential electrical repair experts; we can also support you when you’re experiencing issues with devices powered by electricity like your ceiling fans. Installing a ceiling fan at home can be complicated, especially if you’ve never done it before. Connecting the correct wires may prove to be a challenge, leaving you with a fan that doesn’t turn on. We’re here to repair your ceiling fan, wall fan, pedestal fan so that you can enjoy fresh air circulating throughout your home or office.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"159.00",
    },
    {
        id:85,
        service:"Induction",
        category:"repair",
        title:"Induction - Electric",
        image:"../assets/images/service_detail/in-1.png",
        desc: "Induction not heating, Induction not switching on, Cooktops cracked, induction burner issue",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:86,
        service:"Rice Cooker",
        category:"repair",
        title:"Rice Cooker ",
        image:"../assets/images/service_detail/ck-1.png",
        desc: "Because excessive use of rice cookers creates an issue with its thermal fuse. It happens when the maximum temperature crosses the threshold level on your rice cooker, causing it to overheat. This will then break the thermal fuse, which cuts off the power of your rice cooker to avoid catching fire in your home",
        details:"Please note:<br/>.  Price quote after inspection<br/>.  Repairs and spare parts chargeable as agreed",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:87,
        service:"Air Cooler",
        category:"repair",
        title:"Air Cooler",
        image:"../assets/images/service_detail/cooler-1.png",
        desc: "We have the expertise to repair and service all types of air coolers, desert Coolers, Personal Coolers, tower Coolers, window Coolers, industrial air Cooler. Air cooler grass changing, water pump cleaning or replacement, fan repair and outer cooler pressure jet cleaning.",
        details:"Please note:<br/>.  Price quote after inspection<br/>.  Repairs and spare parts chargeable as agreed",
        rating:"4.8",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:87,
        service:"Air Cooler",
        category:"service",
        title:"Air Cooler",
        image:"../assets/images/service_detail/cooler-1.png",
        desc: "We have the expertise to repair and service all types of air coolers, desert Coolers, Personal Coolers, tower Coolers, window Coolers, industrial air Cooler. Air cooler grass changing, water pump cleaning or replacement, fan repair and outer cooler pressure jet cleaning.",
        details:"Please note:<br/>.  Price quote after inspection<br/>.  Repairs and spare parts chargeable as agreed",
        rating:"4.8",
        reviewcount:"250",
        price:"99.00",
    },
     {
        id:88,
        service:"Mixer",
        category:"repair",
        title:"Mixer Grinder",
        image:"../assets/images/service_detail/mx-1.png",
        desc: "An electrical appliance that you will find in almost every Indian kitchen is a mixer grinder problems Leakage, Blunt Blades, tripping, broken Coupler, motor burning smell, sudden smoke your mixer grinder requires a repair. Your snappy specialist electrician is at you service.  ",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"149.00",
    },
    {
        id:89,
        service:"Food Processor",
        category:"repair",
        title:"Food Processor",
        image:"../assets/images/service_detail/fp-1.png",
        desc: "Food processors may require more repair than other small kitchen appliances. Even so, there are many similarities. The electrical cord, motor, motor fuse, and switches may fail. The blade can become dirty or damaged. The gear or drive belt can become worn or broken. Seals can fail. One of the most common problems is a belt that needs replacing. Snappy are specialist in food processor repair and service.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:90,
        service:"Hand Blender",
        category:"repair",
        title:"Hand Blender",
        image:"../assets/images/service_detail/hb-1.png",
        desc: "Food processors may require more repair than other small kitchen appliances. Even so, there are many similarities. The electrical cord, motor, motor fuse, and switches may fail. The blade can become dirty or damaged. The gear or drive belt can become worn or broken. Seals can fail. One of the most common problems is a belt that needs replacing. Snappy are specialist in food processor repair and service.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:91,
        service:"Hand Blender",
        category:"repair",
        title:"Hand Blender",
        image:"../assets/images/service_detail/hb-1.png",
        desc: "Food processors may require more repair than other small kitchen appliances. Even so, there are many similarities. The electrical cord, motor, motor fuse, and switches may fail. The blade can become dirty or damaged. The gear or drive belt can become worn or broken. Seals can fail. One of the most common problems is a belt that needs replacing. Snappy are specialist in food processor repair and service.",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:101,
        service:"Plumbing",
        category:"install",
        title:"Drain cover installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"139.00",
    },
    {
        id:102,
        service:"Plumbing",
        category:"service",
        title:"Kitchen tile gap filling",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"799.00",
    },
    {
        id:103,
        service:"Plumbing",
        category:"service",
        title:"Bathroom tile gap Filling",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1199.00",
    },
    {
        id:104,
        service:"Plumbing",
        category:"install",
        title:"Drain cover installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"139.00",
    },
    {
        id:105,
        service:"Plumbing",
        category:"service",
        title:"Wash Basin Wash Removal",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"169.00",
    },
    {
        id:106,
        service:"Plumbing",
        category:"install",
        title:"Basin installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"399.00",
    },
    {
        id:107,
        service:"Plumbing",
        category:"service",
        title:"Wash basin pipe leakage",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"109.00",
    },
    {
        id:108,
        service:"Plumbing",
        category:"repair",
        title:"Flush tank repair ( Internal- ceramic )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:109,
        service:"Plumbing",
        category:"repair",
        title:"Flush tank repair ( Internal PVC )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"139.00",
    },
    {
        id:110,
        service:"Plumbing",
        category:"repair",
        title:"Flush tank repair ( Concealed )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:111,
        service:"Plumbing",
        category:"install",
        title:"Westan Toilet Installation ( Floor Mount )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1199.00",
    },
    {
        id:112,
        service:"Plumbing",
        category:"install",
        title:"Westan Toilet repair ( Floor Mount )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"499.00",
    },
    {
        id:113,
        service:"Plumbing",
        category:"service",
        title:"Floor mounted Western Toilet Replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1399.00",
    },
    {
        id:114,
        service:"Plumbing",
        category:"install",
        title:"Western toilet installation ( Wall Mounted )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1499.00",
    },
    {
        id:115,
        service:"Plumbing",
        category:"service",
        title:"Wall Mounted western toilet replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1599.00",
    },
    {
        id:116,
        service:"Plumbing",
        category:"install",
        title:"Indian toilet Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1399.00",
    },
    {
        id:117,
        service:"Plumbing",
        category:"service",
        title:"Flush tank replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1399.00",
    },
    {
        id:118,
        service:"Plumbing",
        category:"service",
        title:"Toilet pot blockage removal",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1149.00",
    },
    {
        id:119,
        service:"Plumbing",
        category:"service",
        title:"Toilet seat cover replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"119.00",
    },
    {
        id:120,
        service:"Plumbing",
        category:"install",
        title:"Jet spray ( Installation / repair )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"119.00",
    },
    {
        id:121,
        service:"Plumbing",
        category:"install",
        title:"Jet spray ( Installation / repair )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"119.00",
    },
    {
        id:122,
        service:"Plumbing",
        category:"service",
        title:"Minor fitting",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"109.00",
    },
    {
        id:123,
        service:"Plumbing",
        category:"install",
        title:"Shower installation ( Wall mount handel )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"149.00",
    },
    {
        id:124,
        service:"Plumbing",
        category:"install",
        title:"Shower installation ( Ceiling Mount )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"149.00",
    },
    {
        id:125,
        service:"Plumbing",
        category:"install",
        title:"Tap Filter installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"129.00",
    },
    {
        id:126,
        service:"Plumbing",
        category:"install",
        title:"Shower Filter Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"159.00",
    },
    {
        id:127,
        service:"Plumbing",
        category:"install",
        title:"Main Line Filer",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"379.00",
    },
    {
        id:128,
        service:"Plumbing",
        category:"service",
        title:"Washing Machine Filter",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"129.00",
    },
    {
        id:129,
        service:"Plumbing",
        category:"service",
        title:"Shower Filter ( Wall mounted )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"129.00",
    },
    {
        id:130,
        service:"Plumbing",
        category:"install",
        title:"Overhead Tank Installation ( 500 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"549.00",
    },
    {
        id:131,
        service:"Plumbing",
        category:"install",
        title:"Overhead Tank Installation ( 500 - 2000 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1149.00",
    },
    {
        id:132,
        service:"Plumbing",
        category:"service",
        title:"Overhead tank Cleaning ( upto 1000 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"949.00",
    },
    {
        id:133,
        service:"Plumbing",
        category:"service",
        title:"Overhead Tank Cleaning ( 1000 - 2000 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1149.00",
    },
    {
        id:134,
        service:"Plumbing",
        category:"service",
        title:"Underground Tank Cleaning ( 1000 - 1500 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1249.00",
    },
    {
        id:135,
        service:"Plumbing",
        category:"service",
        title:"Underground Tank Cleaning ( 1500 - 3000 Ltr )",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"1649.00",
    },
    {
        id:136,
        service:"Plumbing",
        category:"repair",
        title:"Pipe Leakage Repair",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"299.00",
    },
    {
        id:137,
        service:"Plumbing",
        category:"install",
        title:"Water Meter Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"349.00",
    },
    {
        id:137,
        service:"Plumbing",
        category:"install",
        title:"Motor Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"399.00",
    },
    {
        id:138,
        service:"Plumbing",
        category:"service",
        title:"Motor Replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"449.00",
    },
    {
        id:139,
        service:"Plumbing",
        category:"service",
        title:"Motor Air Cavity Removal",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"139.00",
    },
    {
        id:140,
        service:"Plumbing",
        category:"repair",
        title:"Hot & Cold Water mixer repair",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"279.00",
    },
    {
        id:141,
        service:"Plumbing",
        category:"repair",
        title:"Tap repair",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"109.00",
    },
    {
        id:142,
        service:"Plumbing",
        category:"service",
        title:"Tap replacement",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"129.00",
    },
    {
        id:143,
        service:"Plumbing",
        category:"service",
        title:"Water Saving Nozzel",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:144,
        service:"Plumbing",
        category:"install",
        title:"Table Top Hot & Cold Water mixer installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:145,
        service:"Plumbing",
        category:"install",
        title:"Wall Mount hot & Cold water Mixer Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:146,
        service:"Plumbing",
        category:"install",
        title:"Wall Mount tap installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"249.00",
    },
    {
        id:147,
        service:"Plumbing",
        category:"install",
        title:"Table Top tap Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"250",
        price:"99.00",
    },
    {
        id:148,
        service:"Plumbing",
        category:"install",
        title:"Connection House Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"179",
        price:"179.00",
    },
    {
        id:149,
        service:"Plumbing",
        category:"install",
        title:"Washing Machine Intel Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"179",
        price:"129.00",
    },
    {
        id:150,
        service:"Plumbing",
        category:"service",
        title:"Plumber Visit",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"179",
        price:"99.00",
    },
    {
        id:151,
        service:"Plumbing",
        category:"service",
        title:"Minor Repair / Installation",
        image:"../assets/images/service_detail/pb-1.png",
        desc: "",
        details:"Please note<br/>Price quote after inspection<br/>Repairs and spare parts chargeable as agreed.",
        rating:"4.5",
        reviewcount:"179",
        price:"159.00",
    },
];

export default data;